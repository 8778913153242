<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item>货品详情</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <div class="toolbar">
      <el-button size="small" type="info" class="el-icon-back" @click="back()">返回</el-button>
      <el-button size="small" type="primary" class="el-icon-printer" @click="print()">打印</el-button>
    </div>

    <section ref="print" id="print-panel">
    </section>
  </el-card>
</template>
<script>
export default {
  name: "detail",
  components: {},
  data() {
    return {};
  },
  methods: {
    loadData() { },
    back() {
      this.$router.go(-1);
    },
  },
  mounted() { },
  created() {
  },
};
</script>
<style scoped>
</style>